import t from '@jetshop/intl';
import useLoginFields from '@jetshop/core/components/Auth/useLoginFields';
import useSignupForm from '@jetshop/core/components/Auth/useSignupForm';
import { TrendButton } from '@jetshop/ui/Button';
import styled, { css, cx } from 'react-emotion';
import React from 'react';
import Input from '../../Forms/Input';
import { GlobalError } from '../../Forms/GlobalError';

const sectionStyle = css`
  margin-top: 2em;
`;

const StyledTrendButton = styled(TrendButton)`
  align-self: center;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  &:disabled {
    color: #808080;
  }
`;

export default function LoginFields(props) {
  const { fields } = useLoginFields();
  const { globalError, isSubmitting } = useSignupForm();

  function focusSection() {
    if (!props.isActive) props.setActiveSection('loginFields');
  }

  return (
    <section
      className={cx('section', props.isActive && 'active', sectionStyle)}
    >
      {globalError && (
        <GlobalError css={{ marginBottom: '2em' }}>{globalError}</GlobalError>
      )}

      {fields.map(field => {
        return <Input {...field.inputProps} onFocus={focusSection} />;
      })}

      <StyledTrendButton type="submit" disabled={isSubmitting}>
        {isSubmitting ? t('Hold on a moment…') : t('Create Profile')}
      </StyledTrendButton>
    </section>
  );
}
