import t from '@jetshop/intl';
import { ErrorMessage, Field } from 'formik';
import get from 'lodash.get';
import React, { useState } from 'react';
import styled, { css } from 'react-emotion';
import {
  InputError,
  InputPositive,
  ToggleViewPasswordButton
} from './InputStatus';

const Input = styled('input')`
  height: 40px;
  background: #f7f7f7;
  border: 1px solid #dedede;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  margin-bottom: 0.25rem;
  padding-right: 40px;
  ${({ disabled }) =>
    disabled &&
    css`
      background: #ffffff;
      color: #808080;
    `}
`;

export const Wrapper = styled('div')`
  margin-bottom: 1.5rem;
`;

export const ErrorSpan = styled('span')`
  font-style: italic;
  font-size: 12px;
  color: #ff0000;
  position: absolute;
`;

export const Label = styled('label')`
  display: block;
  font-size: 12px;
  margin-bottom: 0.5rem;
  display: block;
  .req {
    color: hsl(0, 0%, 60%);
    margin-left: 0.5em;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      color: #808080;
    `}
`;

const InputWithLabel = ({
  label,
  disabled,
  error,
  success,
  warning,
  loading,
  name,
  required,
  type,
  ...props
}) => {
  const [currentType, setType] = useState(type);
  return (
    <Wrapper>
      <Label disabled={disabled} htmlFor={name}>
        <span>{label || name}</span>
        {required && <span className="req">{t('(Required)')}</span>}
      </Label>
      <Field
        disabled={disabled}
        id={name}
        name={name}
        render={({ field, form: { touched, errors } }) => (
          <div>
            <Input {...props} {...field} type={currentType} />
            {type === 'password' && (
              <ToggleViewPasswordButton
                className={currentType === 'password' ? 'hidden' : 'visible'}
                onClick={() => {
                  currentType === 'password'
                    ? setType('text')
                    : setType('password');
                }}
              />
            )}
            {get(touched, field.name) && get(errors, field.name) && (
              <InputError />
            )}
            {get(touched, field.name) && !get(errors, field.name) && (
              <InputPositive />
            )}
          </div>
        )}
      />
      <ErrorMessage name={name} component={ErrorSpan} />
    </Wrapper>
  );
};

export default InputWithLabel;
