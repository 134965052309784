import styled, { css } from 'react-emotion';

export const smallCaps = css`
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  font-size: 1rem;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-variant: small-caps;
  margin-bottom: 0.75rem;
`;
export const SmallCaps = styled('h1')`
  ${smallCaps};
`;
export const pageTitle = css`
  font-weight: 600;
  line-height: normal;
  font-size: 24px;
  letter-spacing: 0.35rem;
  margin-bottom: 30px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: block;
`;
export const PageTitle = styled('h1')`
  ${pageTitle};
`;
