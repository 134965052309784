import SignupFormProvider from '@jetshop/core/components/Auth/SignupFormContainer';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import t from '@jetshop/intl';
import React, { useState } from 'react';
import styled, { css, cx } from 'react-emotion';
import { Redirect } from 'react-router';
import BaseMaxWidth from '../../Layout/MaxWidth';
import { smallCaps } from '../../ui/Headings';
import { activeSegment, largeSection, paddingBox } from '../UI/Form';
import Address from './Address';
import LoginFields from './LoginFields';
import LoadingPage from '../../LoadingPage';
import { pageTitle } from '../../ui/Headings';

const MaxWidth = styled(BaseMaxWidth)`
  align-items: center;
  margin-bottom: 4rem;
  .heading {
    ${smallCaps};
  }

  p {
    line-height: 1.5;
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }

  button {
    width: 100%;
    max-width: 100%;
  }

  .hint {
    margin-top: 0.5rem;
    font-style: italic;
    font-size: 0.75rem;
    text-align: center;
  }

  .section {
    ${activeSegment};
    :before {
      opacity: 0;
    }
    margin-bottom: 2rem;
    transition: opacity 0.6s ease;
    opacity: 0.5;

    &.active {
      opacity: 1;
      ::before {
        opacity: 1;
      }
    }
  }
`;

const Row = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${({ theme }) => theme.below.lg} {
    display: block;
  }
  > div {
    flex: 1;
    &:first-child {
      margin-right: 1rem;
    }
    &:last-child {
      margin-left: 1rem;
    }

    ${({ theme }) => theme.below.md} {
      margin: 0 !important;
      width: 100%;
      flex: none;
    }
  }
`;

function SignUpPage() {
  const [activeSection, setActiveSection] = useState('address');
  const { loggedIn } = useAuth();

  if (loggedIn) {
    return <Redirect to="/my-pages" />;
  }

  return (
    <SignupFormProvider LoadingComponent={<LoadingPage />}>
      <MaxWidth css={{ marginTop: '2em' }}>
        <section className={cx(largeSection, paddingBox)}>
          <h1 className={pageTitle}>{t('Sign up')}</h1>
          <p style={{ textAlign: 'center' }}>
            {t(
              'Welcome to our online store! We need to create a profile before you continue.'
            )}
          </p>

          <Row>
            <div className="column" onClick={() => setActiveSection('address')}>
              <Address
                isActive={activeSection === 'address'}
                setActiveSection={setActiveSection}
              />
            </div>

            <div
              className="column"
              onClick={() => setActiveSection('loginFields')}
            >
              <LoginFields
                isActive={activeSection === 'loginFields'}
                setActiveSection={setActiveSection}
              />
            </div>
          </Row>
        </section>
      </MaxWidth>
    </SignupFormProvider>
  );
}

export default SignUpPage;
