import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import t from 'format-message';
import React from 'react';
import styled, { css, cx } from 'react-emotion';
import { Link } from 'react-router-dom';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import MaxWidth from '../Layout/MaxWidth';
import TrendButton from '../ui/Button';
import { pageTitle } from '../ui/Headings';
import { activeSegment, smallSection, paddingBox } from './UI/Form';

const StyledTrendButton = styled(TrendButton)`
  align-self: center;
  background: black;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  &:disabled {
    color: #808080;
  }
`;

const TextLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  text-align: right;
  font-size: 0.875em;
  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    margin-left: auto;
  }
`;

export const LogInPageMaxWidth = styled(MaxWidth)`
  align-items: center;
  justify-content: flex-start;
  margin: auto;
  padding: 8rem 2rem 10rem 2rem !important;
  a {
    color: black;
  }
`;

const LoginBottom = styled('div')`
  button {
    margin-bottom: 20px;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

function LogInPage() {
  return (
    <LogInPageMaxWidth>
      <LogInFormProvider redirect="my-pages">
        {({ globalError, isSubmitting, isValid }) => (
          <section className={cx(smallSection, activeSegment, paddingBox)}>
            <header>
              <h1 className={pageTitle}>{t('Login')}</h1>
            </header>
            <Input type="email" name="email" label={t('E-mail address')} />
            <Input type="password" name="password" label={t('Password')} />

            {globalError && (
              <GlobalError style={{ marginBottom: '2em' }}>
                {globalError}
              </GlobalError>
            )}

            <LoginBottom>
              <StyledTrendButton
                type="submit"
                disabled={!isValid}
                loading={isSubmitting ? true : false}
                loadingText={t('Hold on a moment...')}
              >
                {t('Log in')}
              </StyledTrendButton>

              <div>
                <TextLink to="/forgot-password">
                  {t('Forgot password?')}
                </TextLink>

                {/* <TextLink to="/signup" className="signup-link">
                  {t('Not yet a member? Sign Up')}
                </TextLink> */}
              </div>
            </LoginBottom>
          </section>
        )}
      </LogInFormProvider>
    </LogInPageMaxWidth>
  );
}

export default LogInPage;
