import { pageTitle } from '../ui/Headings';
import {
  ForgotPasswordProvider,
  useForgotPasswordData
} from '@jetshop/core/components/Auth/ForgotPasswordProvider';
import Head from '@jetshop/core/components/Head';
import t from '@jetshop/intl';
import React from 'react';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import TrendButton from '../ui/Button';
import { activeSegment, smallSection, paddingBox } from './UI/Form';
import { LogInPageMaxWidth } from './LogInPage';
import styled, { css, cx } from 'react-emotion';

const StyledTrendButton = styled(TrendButton)`
  align-self: center;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  &:disabled {
    color: #808080;
  }
`;

export default function ForgotPasswordPage(props) {
  return (
    <LogInPageMaxWidth
      style={{ alignItems: 'center', marginTop: '8rem', marginBottom: '10rem' }}
    >
      <ForgotPasswordProvider>
        <section className={cx(smallSection, activeSegment, paddingBox)}>
          <h1 className={pageTitle}>{t(`Forgot password`)}</h1>
          <Head data={{ title: 'Forgot Password' }} />
          <ForgotPasswordForm />
        </section>
      </ForgotPasswordProvider>
    </LogInPageMaxWidth>
  );
}

function ForgotPasswordForm() {
  const {
    submitted,
    isSubmitting,
    globalError,
    isValid,
    emailInputProps
  } = useForgotPasswordData();

  if (submitted) {
    return (
      <p style={{ lineHeight: 1.5 }}>
        {t(
          `Your password reset request has been submitted. Check your email for a link to reset your password.`
        )}
      </p>
    );
  }

  return (
    <>
      <Input label={t('What is your email address?')} {...emailInputProps} />

      {globalError && (
        <GlobalError style={{ marginBottom: '1em' }}>{globalError}</GlobalError>
      )}

      <StyledTrendButton
        loading={isSubmitting}
        type="submit"
        disabled={!isValid}
      >
        {t('Submit')}
      </StyledTrendButton>
    </>
  );
}
