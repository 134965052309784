import useAddressFields from '@jetshop/core/components/Auth/useAddressFields';
import t from '@jetshop/intl';
import { CountriesInput } from '@jetshop/ui/Auth/FormFields/CountriesInput';
import styled, { css, cx } from 'react-emotion';
import React from 'react';
import Input, { Label } from '../../Forms/Input';
import { StyledCarrot } from '@jetshop/ui/Select/Components';

const sectionStyle = css`
  margin-top: 2em;
`;

const CountryPicker = styled('div')`
  position: relative;
  select {
    width: 100%;
    height: 38px;
    border-radius: 0;
    appearance: none;
    border: 1px solid #dedede;
    background: white;
    padding: 0 10px;
  }
  svg {
    position: absolute;
    right: 15px;
    bottom: 15px;
    margin: 0;
  }
`;
export default function Address(props) {
  const { fields, setCountryByCode, countries } = useAddressFields();

  function focusSection() {
    // Used to add a highlight to this section when it is active
    if (!props.isActive) props.setActiveSection('address');
  }

  return (
    <section
      className={cx('section', props.isActive && 'active', sectionStyle)}
    >
      {fields.map(field => {
        // Map over billingAddressFields and display them
        return <Input onFocus={focusSection} {...field.inputProps} />;
      })}

      <CountryPicker>
        <Label htmlFor="country">{t('Country')}</Label>
        <CountriesInput
          onFocus={focusSection}
          name="country"
          onChange={e => setCountryByCode(e.currentTarget.value)}
          countries={countries}
        />
        <StyledCarrot />
      </CountryPicker>
    </section>
  );
}
